import React from "react";
import StoryPage from "../../components/story-page";

/** @jsx jsx */
import { jsx, Grid, Button } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/michael-anolik.jpg";
  const fullName = "Michael Anolik";
  const zoomUrl = "https://us02web.zoom.us/j/123456789";
  const zoomPhoneNumber = "(123) 456-7890";
  const zoomMeetingId = "123 456 789";
  const birthDate = "April 1st, 1954";
  const deathDate = "August 25th, 2018";
  const memorialDate = new Date(2022, 8 - 1, 26 - 1);
  const memorialTime = "1PM ET";
  const inviteText =
    "The family wishes everyone to join us in remembering and celebrating the life of Michael Anolik. Please join us in a virtual memorial service on August 26th.";
  const obituary = `Here is Michael's obituary.`;
  const storiesRecordUrl = "https://stories.afterword.com/f9y3zisp9";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rfj5qyhek4r69i6ao90kz7lbnbo0944jmhl26b07";
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      customColumnSpacing="1fr 3fr"
      memorialTime={memorialTime}
      inviteText={inviteText}
      rsvp={false}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      dropboxUrl={dropboxUrl}
    ></StoryPage>
  );
};

export default ThisPage;
